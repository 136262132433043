// project import
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ApiProvider } from "./ApiContext";
import ScrollTop from "./components/ScrollTop";
import Routes from "./routes";
import ThemeCustomization from "./themes";

const App = () => {
  ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  return (
    <ApiProvider>
      <GoogleReCaptchaProvider reCaptchaKey="6Lc5yWgjAAAAANZXN6dF7fci68cey63-dlIf6kcd">
        <ThemeCustomization>
          <ScrollTop>
            <Routes />
          </ScrollTop>
        </ThemeCustomization>
      </GoogleReCaptchaProvider>
    </ApiProvider>
  );
};

export default App;
