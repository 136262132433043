import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GppGoodIcon from "@mui/icons-material/GppGood";
import PersonIcon from "@mui/icons-material/Person";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";

const dashboardSistema = {
  id: "group-dashboard-sistema",
  title: "Sistema",
  url: "/area-admin/sistema",
  type: "group",
  children: [
    {
      id: "",
      title: "RESUMO",
      type: "item",
      url: "",
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "controle-acesso",
      title: "CONTROLE DE ACESSO",
      type: "item",
      url: "controle-acesso",
      icon: GppGoodIcon,
      breadcrumbs: false,
      permissao: "controle-acesso",
    },
    {
      id: "auditoria",
      title: "AUDITORIA",
      type: "item",
      url: "auditoria",
      icon: VerifiedUserIcon,
      breadcrumbs: false,
      permissao: "view-audith",
    },
    {
      id: "usuarios-ativos",
      title: "USUÁRIOS ATIVOS",
      type: "item",
      url: "usuarios-ativos",
      icon: PersonIcon,
      breadcrumbs: false,
    },
    {
      id: "voltar",
      title: "VOLTAR DASH PRINCIPAL",
      type: "item",
      url: "/area-admin",
      icon: ArrowBackIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboardSistema;
