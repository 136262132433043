import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";

const dashboardOperacoes = {
  id: "group-dashboard-operacoes",
  title: "Operações",
  url: "/area-admin/operacoes",
  type: "group",
  children: [
    {
      id: "operacoes",
      title: "OPERAÇÕES",
      type: "item",
      url: "",
      icon: BusinessIcon,
      breadcrumbs: false,
    },
    {
      id: "esteira-cp",
      title: "OPERAÇÕES CP",
      type: "item",
      url: "esteira-cp",
      icon: DashboardIcon,
      breadcrumbs: false,
      permissao: "visualizar-pedidos",
    },
    {
      title: "RELATÓRIOS",
      type: "collapse",
      defaultExpanded: false,
      children: [
        {
          id: "ultimas-parcelas",
          title: "ÚLTIMAS PARCELAS",
          type: "item",
          url: "ultimas-parcelas",
          breadcrumbs: false,
        },
        {
          id: "inatividade",
          title: "INATIVIDADE",
          type: "item",
          url: "inatividade",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "clientes",
      title: "CLIENTES",
      type: "item",
      url: "clientes",
      icon: GroupIcon,
      breadcrumbs: false,
      permissao: "visualizar-clientes",
    },
    {
      id: "voltar",
      title: "VOLTAR DASH PRINCIPAL",
      type: "item",
      url: "/area-admin",
      icon: ArrowBackIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboardOperacoes;
