import { lazy } from "react";
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";

const DashboardMarketing = Loadable(lazy(() => import("../pages/marketing")));
const MetadataMarketing = Loadable(
  lazy(() => import("@/pages/marketing/metadata"))
);

const RelatorioCohort = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/cohort"))
);

const RelatorioCuponsUtilizados = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/cupons-utilizados"))
);

const RelatorioTagsConsolidado = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/dados-tags-consolidados"))
);

const RelatorioFrequenciaCompra = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/frequencia-compra"))
);

const RelatorioProspeccaoAtivo = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/leads-prospeccao-ativa"))
);

const RelatorioPrazoMedio = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/prazo-medio"))
);

const RelatorioTicketMedio = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/ticket-medio"))
);

const RelatorioDadosConsolidadosPorDia = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/dados-consolidados-por-dia"))
);

const CampanhasMarketing = Loadable(
  lazy(() => import("@/pages/marketing/campanhas"))
);
const CadastroCampanhasMarketing = Loadable(
  lazy(() => import("@/pages/marketing/campanhas/cadastro"))
);
const EdicaoCampanhasMarketing = Loadable(
  lazy(() => import("@/pages/marketing/campanhas/editar"))
);
const Cupons = Loadable(lazy(() => import("@/pages/marketing/cupons")));
const CadastroCupons = Loadable(
  lazy(() => import("@/pages/marketing/cupons/cadastro-cupons"))
);
const EditarCupom = Loadable(
  lazy(() => import("@/pages/marketing/cupons/editar-cupom"))
);
const EnvioEmails = Loadable(
  lazy(() => import("@/pages/marketing/envios-emails"))
);
const EnvioSms = Loadable(lazy(() => import("@/pages/marketing/envios-sms")));

const MarketingRoutes = {
  path: "area-admin/marketing",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardMarketing />,
    },
    {
      path: "metadata",
      element: <MetadataMarketing />,
    },
    {
      path: "campanhas",
      element: <CampanhasMarketing />,
    },
    {
      path: "registro-campanhas",
      element: <CadastroCampanhasMarketing />,
    },
    {
      path: "edicao-campanha/:idCampaign",
      element: <EdicaoCampanhasMarketing />,
    },
    {
      path: "cohort",
      element: <RelatorioCohort />,
    },
    {
      path: "cupons-utilizados",
      element: <RelatorioCuponsUtilizados />,
    },
    {
      path: "tags-consolidados",
      element: <RelatorioTagsConsolidado />,
    },
    {
      path: "frequencia-compra",
      element: <RelatorioFrequenciaCompra />,
    },
    {
      path: "prospeccao-ativa",
      element: <RelatorioProspeccaoAtivo />,
    },
    {
      path: "prazo-medio",
      element: <RelatorioPrazoMedio />,
    },
    {
      path: "ticket-medio",
      element: <RelatorioTicketMedio />,
    },
    {
      path: "dados-consolidados-por-dia",
      element: <RelatorioDadosConsolidadosPorDia />,
    },
    {
      path: "cupons",
      element: <Cupons />,
    },
    {
      path: "cadastro-cupons",
      element: <CadastroCupons />,
    },
    {
      path: "editar-cupom/:idCupom",
      element: <EditarCupom />,
    },
    {
      path: "envios-emails",
      element: <EnvioEmails />,
    },
    {
      path: "envios-sms",
      element: <EnvioSms />,
    },
    {
      path: "*",
      element: <DashboardMarketing />,
    },
  ],
};

export default MarketingRoutes;
