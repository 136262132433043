import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const dashboard = {
  id: "group-dashboard-geral",
  title: "Admin",
  url: "/area-admin",
  type: "group",
  children: [
    {
      id: "voltar",
      title: "VOLTAR DASH PRINCIPAL",
      type: "item",
      url: "/area-admin",
      icon: ArrowBackIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
