import {
  get as getApi,
  post as postApi,
  put as putApi,
  remove as removeApi,
} from "@/services/api";
import { createContext, useContext } from "react";

import {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
} from "@/store/reducers/geral";
import { useDispatch } from "react-redux";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const dispatch = useDispatch();

  const post = async (url, data) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        dispatch(activeSpinner());

        const result = await postApi(url, data);
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result);
          return;
        }
        dispatch(cancelSpinner());
        resolve(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        let message =
          "Ocorreu um erro ao consultar os dados. Por favor atualize a página e tente novamente!";
        if (e.status === 400 && e.response?.data?.error) {
          message = e.response.data.error;
        }
        dispatch(
          setInfoDialog({
            open: true,
            descricao: message,
          })
        );
        reject(e);
      }
    });
    return promise;
  };

  const get = async (url) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        dispatch(activeSpinner());

        const result = await getApi(url);
        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result);
          return;
        }
        dispatch(cancelSpinner());
        resolve(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        let message =
          "Ocorreu um erro ao consultar os dados. Por favor atualize a página e tente novamente!";
        if (e.status === 400 && e.response?.data?.error) {
          message = e.response.data.error;
        }
        dispatch(
          setInfoDialog({
            open: true,
            descricao: message,
          })
        );
        reject(e);
      }
    });
    return promise;
  };

  const remove = async (url) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        dispatch(activeSpinner());
        const result = await removeApi(url);
        dispatch(cancelSpinner());

        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result);
          return;
        }

        resolve(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        let message =
          "Ocorreu um erro ao remover os dados. Por favor atualize a página e tente novamente!";

        if (e.status === 400 && e.response?.data?.error) {
          message = e.response.data.error;
        }

        dispatch(
          setInfoDialog({
            open: true,
            descricao: message,
          })
        );
        reject(e);
      }
    });

    return promise;
  };

  const put = async (url, data) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        dispatch(activeSpinner());
        const result = await putApi(url, data);
        dispatch(cancelSpinner());

        if (result.error) {
          dispatch(setInfoDialog({ open: true, descricao: result.error }));
          reject(result);
          return;
        }

        resolve(result);
      } catch (e) {
        console.log(e);
        dispatch(cancelSpinner());
        let message =
          "Ocorreu um erro ao atualizar os dados. Por favor atualize a página e tente novamente!";

        if (e.status === 400 && e.response?.data?.error) {
          message = e.response.data.error;
        }

        dispatch(
          setInfoDialog({
            open: true,
            descricao: message,
          })
        );
        reject(e);
      }
    });

    return promise;
  };

  return (
    <ApiContext.Provider value={{ post, get, remove, put }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  return useContext(ApiContext);
};
