import { lazy } from "react";
import MainLayout from "../layout/MainLayout";
import Loadable from "../components/Loadable";

const DashboardSistema = Loadable(lazy(() => import("@/pages/sistema")));
const Auditoria = Loadable(lazy(() => import("@/pages/sistema/auditoria")));
const ActiveUsers = Loadable(
  lazy(() => import("@/pages/sistema/active-users"))
);
const ControleAcesso = Loadable(lazy(() => import("@/pages/sistema/controle-acesso")));
const PerfilAcesso = Loadable(
  lazy(() => import("@/pages/sistema/controle-acesso/perfilAcesso"))
);
const CadastroPerfilAcesso = Loadable(
  lazy(() => import("@/pages/sistema/controle-acesso/perfilAcesso/cadastro"))
);
const CadastroUsuarioAdmin = Loadable(
  lazy(() => import("@/pages/sistema/controle-acesso/cadastro-usuario"))
);
const EdicaoUsuarioAdmin = Loadable(
  lazy(() => import("@/pages/sistema/controle-acesso/edicao-usuario"))
);

const SistemaRoutes = {
  path: "area-admin/sistema",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardSistema />,
    },
    {
      path: "usuarios-ativos",
      element: <ActiveUsers />,
    },
    {
      path: "auditoria",
      element: <Auditoria />,
    },
    {
      path: "perfis-acesso",
      element: <PerfilAcesso />,
    },

    {
      path: "controle-acesso",
      element: <ControleAcesso />,
    },
    {
      path: "perfil-acesso",
      element: <CadastroPerfilAcesso />,
    },
    {
      path: "perfil-acesso/:idAccessProfile",
      element: <CadastroPerfilAcesso />,
    },
    {
      path: "cadastro-usuario-admin",
      element: <CadastroUsuarioAdmin />,
    },
    {
      path: "edicao-usuario-admin/:idUser",
      element: <EdicaoUsuarioAdmin />,
    },
    {
      path: "*",
      element: <DashboardSistema />,
    },
  ],
};

export default SistemaRoutes;
