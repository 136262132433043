import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BusinessIcon from "@mui/icons-material/Business";
import CampaignIcon from "@mui/icons-material/Campaign";
import CodeIcon from "@mui/icons-material/Code";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DiscountIcon from "@mui/icons-material/Discount";
import EmailIcon from "@mui/icons-material/Email";
import FeedbackIcon from "@mui/icons-material/Feedback";
import GroupIcon from "@mui/icons-material/Group";
import MoneyIcon from "@mui/icons-material/Money";
import PaidIcon from "@mui/icons-material/Paid";
import SettingsIcon from "@mui/icons-material/Settings";

const dashboardGestao = {
  id: "group-dashboard-gestao",
  title: "Gestão",
  url: "/area-admin/gestao",
  type: "group",
  children: [
    {
      id: "operacoes",
      title: "OPERAÇÕES",
      type: "item",
      url: "operacoes",
      icon: BusinessIcon,
      breadcrumbs: false,
    },
    {
      id: "metas",
      title: "METAS",
      type: "item",
      url: "dashboard-metas",
      icon: PaidIcon,
      breadcrumbs: false,
    },
    {
      id: "esteira-cp",
      title: "OPERAÇÕES CP",
      type: "item",
      url: "esteira-cp",
      icon: DashboardIcon,
      breadcrumbs: false,
      permissao: "visualizar-pedidos",
    },
    {
      title: "RELATÓRIOS",
      type: "collapse",
      defaultExpanded: false,
      children: [
        {
          id: "relatorios",
          title: "GERAL",
          type: "item",
          url: "relatorios",
          breadcrumbs: false,
        },
        {
          id: "debitos-x-transacionado",
          title: "DÉBITOS X TRANSACIONADO",
          type: "item",
          url: "debitos-x-transacionado",
          permissao: "view-totals-billvalue-trasaction",
          breadcrumbs: false,
        },
        {
          id: "clientes-novos-por-dia",
          title: "CLIENTES NOVOS POR DIA",
          type: "item",
          url: "clientes-novos-por-dia",
          permissao: "view-total-new-clients-by-day",
          breadcrumbs: false,
        },
        {
          id: "operacoes-por-gateway",
          title: "OPERAÇÕES POR GATEWAY",
          type: "item",
          url: "operacoes-por-gateway",
          permissao: "view-total-finished-by-gateway",
          breadcrumbs: false,
        },
        {
          id: "total-op-por-gateway",
          title: "TOTAL OP POR GATEWAY",
          type: "item",
          url: "total-op-por-gateway",
          permissao: "view-total-finished-by-gateway",
          breadcrumbs: false,
        },
        {
          id: "operacoes-por-parcela",
          title: "OPERAÇÕES POR PARCELA",
          type: "item",
          url: "operacoes-por-parcela",
          permissao: "view-total-finished-by-installment",
          breadcrumbs: false,
        },
        {
          id: "total-clientes",
          title: "TOTAL DE CLIENTES",
          type: "item",
          url: "total-clientes",
          permissao: "view-total-total-clients",
        },
        {
          id: "operacoes-finalizadas-por-dia",
          title: "OPs FINALIZADAS POR DIA",
          type: "item",
          url: "operacoes-finalizadas-por-dia",
          permissao: "view-total-finished-by-day",
          breadcrumbs: false,
        },
        {
          id: "prazo-medio",
          title: "PRAZO MÉDIO",
          type: "item",
          url: "prazo-medio",
          permissao: "view-average-ticket-installments",
          breadcrumbs: false,
        },
        {
          id: "valor-total-de-debitos",
          title: "VALOR TOTAL DE DÉBITOS",
          type: "item",
          url: "valor-total-de-debitos",
          permissao: "view-total-finished-by-day",
          breadcrumbs: false,
        },
        {
          id: "simulacoes-por-dia",
          title: "SIMULAÇÕES POR DIA",
          type: "item",
          url: "simulacoes-por-dia",
          permissao: "view-totals-requests-by-day",
          breadcrumbs: false,
        },
        {
          id: "operacoes-por-uf",
          title: "OPERAÇÕES POR UF",
          type: "item",
          url: "operacoes-por-uf",
          permissao: "view-total-finished-by-province",
          breadcrumbs: false,
        },
        {
          id: "total-op-por-uf",
          title: "TOTAL OP POR UF",
          type: "item",
          url: "total-op-por-uf",
          permissao: "view-total-finished-by-province",
          breadcrumbs: false,
        },
        {
          id: "totais-chargeback",
          title: "TOTAIS CHARGEBACK",
          type: "item",
          url: "totais-chargeback",
          permissao: "view-total-chargebacks",
          breadcrumbs: false,
        },
        {
          id: "totais-por-tipo-pagamento",
          title: "TOTAIS POR TIPO DE PGTO",
          type: "item",
          url: "totais-por-tipo-pagamento",
          permissao: "view-total-requests-per-type-request_bills",
          breadcrumbs: false,
        },
        {
          id: "taxa-conversao",
          title: "TAXA DE CONVERSÃO",
          type: "item",
          url: "taxa-conversao",
          permissao: "view-totals-requests",
          breadcrumbs: false,
        },
        {
          id: "custos-por-dia",
          title: "CUSTOS POR DIA",
          type: "item",
          url: "custos-por-dia",
          permissao: "view-totals-costs-by-day",
          breadcrumbs: false,
        },
        {
          id: "custos-de-operacoes",
          title: "CUSTOS DE OPERAÇÕES",
          type: "item",
          url: "custos-de-operacoes",
          permissao: "view-total-costs-requests",
          breadcrumbs: false,
        },
        {
          id: "percentual-custos-gerais",
          title: "PERCENTUAL CUSTOS GERAIS",
          type: "item",
          url: "percentual-custos-gerais",
          permissao: "view-percent-costs-requests",
          breadcrumbs: false,
        },
        {
          id: "faturamento-por-dia",
          title: "FATURAMENTO POR DIA",
          type: "item",
          url: "faturamento-por-dia",
          permissao: "view-totals-invoice-by-day",
          breadcrumbs: false,
        },
        {
          id: "faturamento-por-periodo",
          title: "FATURAMENTO POR PERÍODO",
          type: "item",
          url: "faturamento-por-periodo",
          permissao: "view-totals-invoice-by-period",
          breadcrumbs: false,
        },
        {
          id: "geral-por-dia",
          title: "GERAL POR DIA",
          type: "item",
          url: "geral-por-dia",
          permissao: "view-total-months-by-day",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "clientes",
      title: "CLIENTES",
      type: "item",
      url: "clientes",
      icon: GroupIcon,
      breadcrumbs: false,
      permissao: "visualizar-clientes",
    },
    {
      id: "listagem-parceiros",
      title: "PARCEIROS",
      type: "item",
      url: "listagem-parceiros",
      icon: BusinessIcon,
      breadcrumbs: false,
    },
    {
      title: "RELATÓRIOS PARCEIROS",
      type: "collapse",
      defaultExpanded: false,
      children: [
        {
          id: "geral-parceiros",
          title: "GERAL PARCEIROS",
          type: "item",
          url: "geral-parceiros",
          breadcrumbs: false,
        },
        {
          id: "detalhamento-parceiros",
          title: "DETALHAMENTO PARCEIROS",
          type: "item",
          url: "detalhamento-parceiros",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "transferencias",
      title: "SALDOS/TRANSFERÊNCIAS",
      type: "item",
      url: "transferencias",
      icon: MoneyIcon,
      breadcrumbs: false,
      permissao: "visualizar-saldos",
      badge: true,
    },
    {
      id: "tipos-de-custos",
      title: "TIPOS DE CUSTOS",
      type: "item",
      url: "tipos-de-custos",
      icon: MoneyIcon,
      permissao: "view-type-costs",
      breadcrumbs: false,
    },
    {
      id: "custos",
      title: "CUSTOS",
      type: "item",
      url: "custos",
      icon: MoneyIcon,
      permissao: "view-costs",
      breadcrumbs: false,
    },
    {
      id: "lancamentos",
      title: "LANÇAMENTOS",
      type: "item",
      url: "lancamentos",
      icon: MoneyIcon,
      breadcrumbs: false,
    },
    {
      id: "metadata",
      title: "METADATA",
      type: "item",
      url: "metadata",
      icon: CodeIcon,
      breadcrumbs: false,
      permissao: "visualizar-dados-marketing",
    },
    {
      id: "campanhas",
      title: "CAMPANHAS",
      type: "item",
      url: "campanhas",
      icon: CampaignIcon,
      breadcrumbs: false,
      permissao: "visualizar-dados-marketing",
    },
    {
      title: "RELATÓRIOS MARKETING",
      type: "collapse",
      defaultExpanded: false,
      children: [
        {
          id: "cohort",
          title: "COHORT",
          type: "item",
          url: "cohort",
          breadcrumbs: false,
        },
        {
          id: "cupons-utilizados",
          title: "CUPONS UTILIZADOS",
          type: "item",
          url: "cupons-utilizados",
          breadcrumbs: false,
        },
        {
          id: "tags-consolidados",
          title: "TOTAIS OPERAÇÕES POR TAGS",
          type: "item",
          url: "tags-consolidados",
          breadcrumbs: false,
        },
        {
          id: "frequencia-compra",
          title: "FREQUÊNCIA DE COMPRA",
          type: "item",
          url: "frequencia-compra",
          breadcrumbs: false,
        },
        {
          id: "prospeccao-ativa",
          title: "LEADS PROSPECÇÃO ATIVA",
          type: "item",
          url: "prospeccao-ativa",
          breadcrumbs: false,
        },
        {
          id: "prazo-medio-clientes-novos",
          title: "PRAZO MÉDIO",
          type: "item",
          url: "prazo-medio-clientes-novos",
          breadcrumbs: false,
        },
        {
          id: "ticket-medio",
          title: "TICKET MÉDIO",
          type: "item",
          url: "ticket-medio",
          breadcrumbs: false,
        },
        {
          id: "dados-consolidados-por-dia",
          title: "DADOS CONSOLIDADOS",
          type: "item",
          url: "dados-consolidados-por-dia",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "cupons",
      title: "CUPONS",
      type: "item",
      url: "cupons",
      icon: DiscountIcon,
      breadcrumbs: false,
      permissao: "visualizar-cupons",
    },
    {
      id: "envios-emails",
      title: "ENVIO EMAILS",
      type: "item",
      url: "envios-emails",
      icon: EmailIcon,
      breadcrumbs: false,
    },
    {
      id: "envios-sms",
      title: "ENVIO SMS",
      type: "item",
      url: "envios-sms",
      icon: FeedbackIcon,
      breadcrumbs: false,
    },
    {
      id: "gateways",
      title: "GATEWAYS",
      type: "item",
      url: "gateways",
      icon: SettingsIcon,
      breadcrumbs: false,
      permissao: "configurar-gateways",
    },
    {
      id: "configuracoes-gerais",
      title: "CONFIGURAÇÕES",
      type: "item",
      url: "configuracoes",
      icon: SettingsIcon,
      breadcrumbs: false,
      permissao: "configuracoes-gerais",
    },

    {
      id: "voltar",
      title: "VOLTAR DASH PRINCIPAL",
      type: "item",
      url: "/area-admin",
      icon: ArrowBackIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboardGestao;
