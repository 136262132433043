import { useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BotaoText from "./BotaoText";
import { Typography } from "@mui/material";

export default function DialogEnvioRelatorio({
  infoDialogEnvioRelatorio,
  setInfoDialogEnvioRelatorio,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setInfoDialogEnvioRelatorio({ open: false }));
  };
  const handleOk = () => {
    if (infoDialogEnvioRelatorio.acaoOk) {
      infoDialogEnvioRelatorio.acaoOk();
      handleClose();
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      open={infoDialogEnvioRelatorio.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{"Atenção"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>
            Solicitação realizada com sucesso! O relatório está sendo gerado e
            será enviado diretamente para o seu e-mail.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BotaoText onClick={handleOk} autoFocus>
          Ok
        </BotaoText>
      </DialogActions>
    </Dialog>
  );
}
