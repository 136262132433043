import { lazy } from "react";
import Loadable from "@/components/Loadable";
import MainLayout from "@/layout/MainLayout";
const DashboardPrincipal = Loadable(lazy(() => import("@/pages/dashboard")));

const UserWarnings = Loadable(
  lazy(() => import("@/pages/dashboard/user-warnings/ListWarnings"))
);
const DetailsUserWarning = Loadable(
  lazy(() => import("@/pages/dashboard/user-warnings/details-warning"))
);
const ConfigUserNotifications = Loadable(
  lazy(() => import("@/pages/dashboard/user-warnings/configs-notifications"))
);

const AlterarSenha = Loadable(
  lazy(() => import("@/pages/dashboard/alterar-senha"))
);

const MainRoutes = {
  path: "area-admin",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardPrincipal />,
    },
    {
      path: "alterar-senha",
      element: <AlterarSenha />,
    },
    {
      path: "avisos-usuario",
      element: <UserWarnings />,
    },
    {
      path: "avisos-usuario/detalhes/:id",
      element: <DetailsUserWarning />,
    },
    {
      path: "avisos-usuario/configuracoes",
      element: <ConfigUserNotifications />,
    },

    {
      path: "*",
      element: <DashboardPrincipal />,
    },
  ],
};

export default MainRoutes;
