import { lazy } from "react";
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";

const DashboardParceiros = Loadable(lazy(() => import("@/pages/parceiros")));

const ListagemParceiros = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro"))
);

const PagamentosParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/pagamentos"))
);
const UsuariosParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/usuarios-parceiro"))
);
const EdicaoParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/edicao-parceiro"))
);
const EdicaoWhiteLabelParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/white-label"))
);
const EdicaoTCParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/editar-tc"))
);
const EdicaoFaixaComissao = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/editar-faixa-comissao"))
);
const EditarTaxasParcelas = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/editar-taxas-por-parcela"))
);
const Usuario = Loadable(
  lazy(() => import("@/pages/operacoes/clientes/usuario"))
);

const Transferencias = Loadable(
  lazy(() => import("@/pages/parceiros/transferencias"))
);
const ComprovanteTransferencia = Loadable(
  lazy(() => import("@/pages/parceiros/transferencias/comprovante"))
);

const RelatoriosParceiros = Loadable(
  lazy(() => import("@/pages/parceiros/relatorios"))
);

const RelatorioGeralParceiros = Loadable(
  lazy(() => import("@/pages/parceiros/relatorios/geral-parceiros"))
);

const RelatorioDetalhamentoParceiros = Loadable(
  lazy(() => import("@/pages/parceiros/relatorios/detalhamento-parceiros"))
);

const ParceirosRoutes = {
  path: "area-admin/parceiros",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardParceiros />,
    },
    {
      path: "listagem-parceiros",
      element: <ListagemParceiros />,
    },
    {
      path: "pagamentos-parceiro/:idCompany",
      element: <PagamentosParceiro />,
    },
    {
      path: "usuarios-parceiro/:idCompany",
      element: <UsuariosParceiro />,
    },
    {
      path: "editar-parceiro/:idCompany",
      element: <EdicaoParceiro />,
    },
    {
      path: "white-label/:idCompany",
      element: <EdicaoWhiteLabelParceiro />,
    },
    {
      path: "tc-parceiro/:idCompany",
      element: <EdicaoTCParceiro />,
    },
    {
      path: "faixa-comissao/:idCompany",
      element: <EdicaoFaixaComissao />,
    },
    {
      path: "taxas-parcelas/:idCompany",
      element: <EditarTaxasParcelas />,
    },
    {
      path: "usuario/company/:idCompany",
      element: <Usuario />,
    },
    {
      path: "usuario/company/:idCompany/:idUser",
      element: <Usuario />,
    },
    {
      path: "transferencias",
      element: <Transferencias />,
    },
    {
      path: "comprovante-transferencia/:idTransfer",
      element: <ComprovanteTransferencia />,
    },
    {
      path: "relatorios",
      element: <RelatoriosParceiros />,
    },
    {
      path: "geral-parceiros",
      element: <RelatorioGeralParceiros />,
    },
    {
      path: "detalhamento-parceiros",
      element: <RelatorioDetalhamentoParceiros />,
    },
    {
      path: "*",
      element: <DashboardParceiros />,
    },
  ],
};

export default ParceirosRoutes;
