import { lazy } from "react";
import MainLayout from "../layout/MainLayout";
import Loadable from "../components/Loadable";
import InfoMoviPay from "../pages/movipay/transacoes-movipay/infoMoviPay";

const DashboardMovipay = Loadable(lazy(() => import("@/pages/movipay/saldos-movipay")));

const TransacoesMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/transacoes-movipay"))
);
const ChargebackMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/chargeback-movipay"))
);

const TaxasParceiroMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/parceiros-movipay/taxas"))
);

const UsuariosParceiroMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/parceiros-movipay/usuarios-parceiro"))
);

const UsuarioMoviPay = Loadable(
  lazy(() =>
    import("@/pages/movipay/parceiros-movipay/usuarios-parceiro/usuario")
  )
);

const ParceirosMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/parceiros-movipay"))
);

const EdicaoParceiroMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/parceiros-movipay/edicao-parceiro"))
);

const EdicaoWhiteLabelParceiroMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/parceiros-movipay/white-label"))
);

const DetalheChargeback = Loadable(
  lazy(() => import("@/pages/movipay/chargeback-movipay/DetalheChargeback"))
);
const AntecipacoesMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/antecipacao-movipay"))
);
const TransferenciasMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/transferencias-movipay"))
);
const ConfiguracoesMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/configuracao-movipay"))
);
const SaldosMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/saldos-movipay"))
);
const AntecipacaoMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/antecipacao-movipay/edicao-antecipacao"))
);
const TransferenciaMoviPay = Loadable(
  lazy(() => import("@/pages/movipay/transferencias-movipay/edicao"))
);

const MovipayRoutes = {
  path: "area-admin/movipay",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardMovipay />,
    },
    {
      path: "transacoes-movipay",
      element: <TransacoesMoviPay />,
    },
    {
      path: "transacoes-movipay/:id",
      element: <InfoMoviPay />,
    },
    {
      path: "antecipacoes-movipay",
      element: <AntecipacoesMoviPay />,
    },
    {
      path: "antecipacao-movipay/:idAntecipacao",
      element: <AntecipacaoMoviPay />,
    },
    {
      path: "transferencias-movipay",
      element: <TransferenciasMoviPay />,
    },
    {
      path: "transferencia-movipay/:idTransfer",
      element: <TransferenciaMoviPay />,
    },
    {
      path: "configuracoes-movipay",
      element: <ConfiguracoesMoviPay />,
    },
    {
      path: "saldos-movipay",
      element: <SaldosMoviPay />,
    },
    {
      path: "chargeback-movipay",
      element: <ChargebackMoviPay />,
    },
    {
      path: "chargeback-movipay/:id",
      element: <DetalheChargeback />,
    },
    {
      path: "parceiros-movipay",
      element: <ParceirosMoviPay />,
    },
    {
      path: "taxas-parceiro-movipay/:idCompany",
      element: <TaxasParceiroMoviPay />,
    },

    {
      path: "usuarios-parceiro-movipay/:idCompany",
      element: <UsuariosParceiroMoviPay />,
    },

    {
      path: "parceiro-movipay/:idCompany",
      element: <EdicaoParceiroMoviPay />,
    },

    {
      path: "parceiro-movipay/white-label/:idCompany",
      element: <EdicaoWhiteLabelParceiroMoviPay />,
    },

    {
      path: "usuario-movipay/:idUser/:idCompany",
      element: <UsuarioMoviPay />,
    },

    {
      path: "*",
      element: <DashboardMovipay />,
    },
  ],
};

export default MovipayRoutes;
