import { useRoutes } from "react-router-dom";

// project import
import AreaAbertaRoutes from "./AreaAbertaRoutes";
import DashboardRoutes from "./DashboardRoutes";
import MarketingRoutes from "./MarketingRoutes";
import ParceirosRoutes from "./ParceirosRoutes";
import OperacoesRoutes from "./OperacoesRoutes";
import SistemaRoutes from "./SistemaRoutes";
import MovipayRoutes from "./MovipayRoutes";
import GestaoRoutes from "./GestaoRoutes";
import MetasRoutes from "./MetasRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    AreaAbertaRoutes,
    DashboardRoutes,
    OperacoesRoutes,
    MarketingRoutes,
    ParceirosRoutes,
    SistemaRoutes,
    GestaoRoutes,
    MovipayRoutes,
    MetasRoutes,
  ]);
}
