import Breadcrumbs from "@/components/@extended/Breadcrumbs";
import Dialog from "@/components/Dialog";
import DialogConfirmacao from "@/components/DialogConfirmacao";
import DialogContrato from "@/components/DialogContrato";
import DialogEnvioRelatorio from "@/components/DialogEnvioRelatorio";
import DialogFormatoRelatorio from "@/components/DialogFormatoRelatorio";
import DialogSimulationType from "@/components/DialogSimulationType";
import Spinner from "@/components/Spinner";
import Toaster from "@/components/Toaster";
import navigation from "@/menu-items";
import { ehDashboardPrincipal } from "@/services/utils";
import {
  setInfoDialog,
  setInfoDialogConfirmacao,
  setInfoDialogContrato,
  setInfoDialogEnvioRelatorio,
  setInfoDialogFormatoRelatorio,
  setInfoDialogSimulationType,
} from "@/store/reducers/geral";
import { openDrawer } from "@/store/reducers/menu";
import { Box, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Header from "./Header";

import { isMobile } from "react-device-detect";
import Drawer from "./DrawerGeral";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const { drawerOpen } = useSelector((state) => state.menu);
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const geral = useSelector((state) => state.geral);
  const [open, setOpen] = useState(drawerOpen);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  useEffect(() => {
    const abrirMenu = !matchDownLG && !ehDashboardPrincipal();

    setOpen(abrirMenu);
    dispatch(openDrawer({ drawerOpen: abrirMenu }));
  }, [matchDownLG, dispatch]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const retornaDrawerDashboard = () => {
    if (ehDashboardPrincipal()) {
      return null;
    }

    if (!isMobile) {
      dispatch(openDrawer({ drawerOpen: true }));
    }

    return <Drawer {...{ open, handleDrawerToggle }} />;
  };

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />

      {retornaDrawerDashboard()}

      <Box
        component="main"
        sx={{ width: "100%", flexGrow: 1, p: { xs: 2, sm: 3 } }}>
        <Toolbar />
        <Breadcrumbs
          navigation={navigation}
          title
          titleBottom
          card={false}
          divider={false}
        />
        <Outlet />
      </Box>
      {geral?.infoDialog?.open && (
        <Dialog {...{ infoDialog: geral.infoDialog, setInfoDialog }} />
      )}
      <Spinner {...{ showSpinner: geral.showSpinner }}></Spinner>
      <Toaster {...{ infoToaster: geral.infoToaster }}></Toaster>
      {geral?.infoDialogConfirmacao.open && (
        <DialogConfirmacao
          {...{
            infoDialogConfirmacao: geral.infoDialogConfirmacao,
            setInfoDialogConfirmacao,
          }}
        />
      )}
      {geral?.infoDialogFormatoRelatorio.open && (
        <DialogFormatoRelatorio
          {...{
            infoDialogFormatoRelatorio: geral.infoDialogFormatoRelatorio,
            setInfoDialogFormatoRelatorio,
          }}
        />
      )}
      {geral?.infoDialogSimulationType.open && (
        <DialogSimulationType
          {...{
            infoDialogSimulationType: geral.infoDialogSimulationType,
            setInfoDialogSimulationType,
          }}
        />
      )}

      {geral.infoDialogContrato.open && (
        <DialogContrato
          {...{
            infoDialogContrato: geral.infoDialogContrato,
            setInfoDialogContrato,
          }}
        />
      )}

      {geral.infoDialogEnvioRelatorio.open && (
        <DialogEnvioRelatorio
          {...{
            infoDialogEnvioRelatorio: geral.infoDialogEnvioRelatorio,
            setInfoDialogEnvioRelatorio,
          }}
        />
      )}
    </Box>
  );
};

export default MainLayout;
