import { lazy } from "react";
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";

const DashboardOperacoes = Loadable(lazy(() => import("@/pages/operacoes")));

const Pedido = Loadable(lazy(() => import("@/pages/operacoes/pedido")));
const Estorno = Loadable(
  lazy(() => import("@/pages/operacoes/pedido/estorno"))
);
const Comprovante = Loadable(
  lazy(() =>
    import(
      "@/pages/operacoes/pedido/pagamentos-solicitados/comprovante-pagamento"
    )
  )
);
const ComprovanteEstorno = Loadable(
  lazy(() =>
    import(
      "@/pages/operacoes/pedido/pagamentos-solicitados/comprovante-estorno"
    )
  )
);
const ExtratoOperacoesLiquidadas = Loadable(
  lazy(() => import("@/pages/operacoes/resumo/extrato-operacoes-liquidadas"))
);

const Clientes = Loadable(lazy(() => import("@/pages/operacoes/clientes")));
const Usuario = Loadable(
  lazy(() => import("@/pages/operacoes/clientes/usuario"))
);
const AlteracaoUsuario = Loadable(
  lazy(() => import("@/pages/operacoes/clientes/alteracao-usuario"))
);
const IndicacoesClientes = Loadable(
  lazy(() => import("@/pages/operacoes/clientes/indicacoes-clientes"))
);

const EsteiraCP = Loadable(
  lazy(() => import("@/pages/operacoes/esteira-pedidos-cp"))
);

const RelatorioUltimasParcelas = Loadable(
  lazy(() => import("@/pages/operacoes/relatorios/ultimas-parcelas"))
);

const RelatorioInatividade = Loadable(
  lazy(() => import("@/pages/operacoes/relatorios/inatividade"))
);

const OperacoesRoutes = {
  path: "area-admin/operacoes",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardOperacoes />,
    },
    {
      path: "pedido/:idRequest",
      element: <Pedido />,
    },
    {
      path: "recibo-operacao/:idRequest/:token/:idRequestBill",
      element: <Comprovante />,
    },
    {
      path: "pedido/:idRequest/estorno",
      element: <Estorno />,
    },
    {
      path: "comprovante-estorno/:idRequest/:idRequestBill",
      element: <ComprovanteEstorno />,
    },
    {
      path: "operacoes-liquidadas",
      element: <ExtratoOperacoesLiquidadas />,
    },
    {
      path: "indicacoes-clientes",
      element: <IndicacoesClientes />,
    },
    {
      path: "clientes",
      element: <Clientes />,
    },
    {
      path: "usuario",
      element: <Usuario />,
    },
    {
      path: "usuario/:idUser",
      element: <AlteracaoUsuario />,
    },
    {
      path: "esteira-cp",
      element: <EsteiraCP />,
    },
    {
      path: "ultimas-parcelas",
      element: <RelatorioUltimasParcelas />,
    },
    {
      path: "inatividade",
      element: <RelatorioInatividade />,
    },

    {
      path: "*",
      element: <DashboardOperacoes />,
    },
  ],
};

export default OperacoesRoutes;
