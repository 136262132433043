import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsIcon from "@mui/icons-material/Settings";
import MoneyIcon from "@mui/icons-material/Money";

const dashboard = {
  id: "group-dashboard-movipay",
  title: "Movipay",
  type: "group",
  url: "/area-admin/movipay",
  children: [
    {
      id: "saldos-movipay",
      title: "SALDOS",
      type: "item",
      url: "saldos-movipay",
      icon: MonetizationOnIcon,
      breadcrumbs: false,
      permissao: "acesso-quitapay",
    },
    {
      id: "transacoes-movipay",
      title: "TRANSAÇÕES",
      type: "item",
      url: "transacoes-movipay",
      icon: DashboardIcon,
      breadcrumbs: false,
      permissao: "acesso-quitapay",
    },

    {
      id: "antecipacoes-movipay",
      title: "ANTECIPAÇÕES",
      type: "item",
      url: "antecipacoes-movipay",
      icon: BusinessIcon,
      breadcrumbs: false,
      permissao: "acesso-quitapay",
    },
    {
      id: "transferencias-movipay",
      title: "TRANSFERÊNCIAS",
      type: "item",
      url: "transferencias-movipay",
      icon: MoneyIcon,
      breadcrumbs: false,
      permissao: "acesso-quitapay",
    },
    {
      id: "chargeback-movipay",
      title: "CHARGEBACKS",
      type: "item",
      url: "chargeback-movipay",
      icon: BusinessIcon,
      breadcrumbs: false,
      permissao: "acesso-quitapay",
    },

    {
      id: "parceiros-movipay",
      title: "PARCEIROS",
      type: "item",
      url: "parceiros-movipay",
      icon: BusinessIcon,
      breadcrumbs: false,
      permissao: "acesso-quitapay",
    },
    {
      id: "configuracoes-movipay",
      title: "CONFIGURAÇÕES",
      type: "item",
      url: "configuracoes-movipay",
      icon: SettingsIcon,
      breadcrumbs: false,
      permissao: "acesso-quitapay",
    },
    {
      id: "voltar",
      title: "VOLTAR DASH PRINCIPAL",
      type: "item",
      url: "/area-admin",
      icon: ArrowBackIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
