import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaidIcon from "@mui/icons-material/Paid";
const dashboardMetas = {
  id: "group-dashboard-metas",
  title: "Metas",
  url: "/area-admin/metas",
  type: "group",
  children: [
    {
      id: "",
      title: "METAS",
      type: "item",
      url: "",
      icon: PaidIcon,
      breadcrumbs: false,
    },
    {
      id: "voltar",
      title: "VOLTAR DASH PRINCIPAL",
      type: "item",
      url: "/area-admin",
      icon: ArrowBackIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboardMetas;
