import { lazy } from "react";
import MainLayout from "../layout/MainLayout";
import Loadable from "../components/Loadable";

const DashboardMetas = Loadable(lazy(() => import("@/pages/metas")));

const MetasRoutes = {
  path: "area-admin/metas",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <DashboardMetas />,
    },
    {
      path: "*",
      element: <DashboardMetas />,
    },
  ],
};

export default MetasRoutes;
