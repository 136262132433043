import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSpinner: false,
  infoDialog: {
    open: false,
    titulo: "",
    descricao: "",
  },
  infoToaster: {},
  infoDialogConfirmacao: {},
  infoDialogFormatoRelatorio: {},
  infoDialogSimulationType: {},
  infoDialogContrato: {},
  infoDialogEnvioRelatorio: {},
};

const geral = createSlice({
  name: "geral",
  initialState,
  reducers: {
    activeSpinner(state) {
      state.showSpinner = true;
    },
    cancelSpinner(state) {
      state.showSpinner = false;
    },
    setInfoDialog(state, action) {
      state.infoDialog = action.payload;
    },
    setInfoDialogConfirmacao(state, action) {
      state.infoDialogConfirmacao = action.payload;
    },
    setInfoDialogFormatoRelatorio(state, action) {
      state.infoDialogFormatoRelatorio = action.payload;
    },
    setInfoDialogSimulationType(state, action) {
      state.infoDialogSimulationType = action.payload;
    },
    setInfoToaster(state, action) {
      state.infoToaster = action.payload;
    },
    setInfoDialogContrato(state, action) {
      state.infoDialogContrato = action.payload;
    },
    setInfoDialogEnvioRelatorio(state, action) {
      state.infoDialogEnvioRelatorio = action.payload;
    },
    resetGeral(state) {
      state.showSpinner = initialState.showSpinner;
      state.infoToaster = initialState.infoToaster;
      state.infoDialogConfirmacao = initialState.infoDialogConfirmacao;
      state.infoDialog = initialState.infoDialog;
      state.infoDialogFormatoRelatorio =
        initialState.infoDialogFormatoRelatorio;
      state.infoDialogSimulationType = initialState.infoDialogSimulationType;
      state.infoDialogContrato = initialState.infoDialogContrato;
      state.infoDialogEnvioRelatorio = initialState.infoDialogEnvioRelatorio;
    },
  },
});

export default geral.reducer;

export const {
  activeSpinner,
  cancelSpinner,
  setInfoDialog,
  setInfoToaster,
  resetGeral,
  setInfoDialogConfirmacao,
  setInfoDialogFormatoRelatorio,
  setInfoDialogSimulationType,
  setInfoDialogContrato,
  setInfoDialogEnvioRelatorio,
} = geral.actions;
