import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { retornaUrlDashboard } from "@/services/utils";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PersonIcon from "@mui/icons-material/Person";

const Profile = () => {
  const [anchorElOpcoesPerfil, setAnchorElOpcoesPerfil] = useState(null);
  const openOpcoesPerfil = Boolean(anchorElOpcoesPerfil);
  const navigation = useNavigate();

  const handleClickOpcoesPerfil = (event) => {
    setAnchorElOpcoesPerfil(event.currentTarget);
  };
  const handleLogout = async () => {
    localStorage.removeItem("dadosUsuarioLogado");
    window.location.href = "/";
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Button
        id="button-opcoes-perfil"
        aria-controls={openOpcoesPerfil ? "basic-menu" : undefined}
        aria-haspopup="true"
        style={{ color: "white" }}
        aria-expanded={openOpcoesPerfil ? "true" : undefined}
        onClick={handleClickOpcoesPerfil}>
        <PersonIcon style={{ fontSize: "2rem" }}></PersonIcon>
      </Button>
      <Menu
        id="button-opcoes-perfil"
        anchorEl={anchorElOpcoesPerfil}
        open={openOpcoesPerfil}
        onClose={() => {
          setAnchorElOpcoesPerfil(null);
        }}
        MenuListProps={{
          "aria-labelledby": "button-opcoes-perfil",
        }}>
        <MenuItem
          onClick={() => {
            setAnchorElOpcoesPerfil(null);
            navigation(retornaUrlDashboard("alterar-senha"));
          }}>
          ALTERAR SENHA
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorElOpcoesPerfil(null);
            handleLogout();
          }}>
          SAIR
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Profile;
