import { Box, List, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  AccordionDetailsNavGroup,
  AccordionNavGroup,
  AccordionSummaryNavGroup,
} from "./AccordionNavGroup";
import NavItem from "./NavItem";

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const user = useSelector((state) => state.user);
  const { drawerOpen } = menu;

  const navCollapse = item.children?.map((menuItem) => {
    if (menuItem.permissao) {
      if (!user.permissions[menuItem.permissao]) {
        return <div key={menuItem.id}></div>;
      }
    }
    switch (menuItem.type) {
      case "collapse":
        return (
          <AccordionNavGroup defaultExpanded={menuItem.defaultExpanded}>
            <AccordionSummaryNavGroup>
              <Typography>{menuItem.title}</Typography>
            </AccordionSummaryNavGroup>
            <AccordionDetailsNavGroup>
              {menuItem.children?.map((menuItemChildren) => (
                <>
                  {menuItemChildren.permissao &&
                  !user.permissions[menuItemChildren.permissao] ? null : (
                    <NavItem
                      key={menuItemChildren.id}
                      item={menuItemChildren}
                      level={1}
                    />
                  )}
                </>
              ))}
            </AccordionDetailsNavGroup>
          </AccordionNavGroup>
        );
      case "item":
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography
            key={menuItem.id}
            variant="h6"
            color="error"
            align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>
            {/* only available in paid version */}
          </Box>
        )
      }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}>
      {navCollapse}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
