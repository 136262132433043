import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CampaignIcon from "@mui/icons-material/Campaign";
import CodeIcon from "@mui/icons-material/Code";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DiscountIcon from "@mui/icons-material/Discount";
import EmailIcon from "@mui/icons-material/Email";
import FeedbackIcon from "@mui/icons-material/Feedback";

const dashboardMarketing = {
  id: "group-dashboard-marketing",
  title: "Marketing",
  url: "/area-admin/marketing",
  type: "group",
  children: [
    {
      id: "",
      title: "RESUMO",
      type: "item",
      url: "",
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "metadata",
      title: "METADATA",
      type: "item",
      url: "metadata",
      icon: CodeIcon,
      breadcrumbs: false,
      permissao: "visualizar-dados-marketing",
    },
    {
      id: "campanhas",
      title: "CAMPANHAS",
      type: "item",
      url: "campanhas",
      icon: CampaignIcon,
      breadcrumbs: false,
      permissao: "visualizar-dados-marketing",
    },
    {
      title: "RELATÓRIOS",
      type: "collapse",
      defaultExpanded: false,
      children: [
        {
          id: "cohort",
          title: "COHORT",
          type: "item",
          url: "cohort",
          breadcrumbs: false,
        },
        {
          id: "cupons-utilizados",
          title: "CUPONS UTILIZADOS",
          type: "item",
          url: "cupons-utilizados",
          breadcrumbs: false,
        },
        {
          id: "tags-consolidados",
          title: "TOTAIS OPERAÇÕES POR TAGS",
          type: "item",
          url: "tags-consolidados",
          breadcrumbs: false,
        },
        {
          id: "frequencia-compra",
          title: "FREQUÊNCIA DE COMPRA",
          type: "item",
          url: "frequencia-compra",
          breadcrumbs: false,
        },
        {
          id: "prospeccao-ativa",
          title: "LEADS PROSPECÇÃO ATIVA",
          type: "item",
          url: "prospeccao-ativa",
          breadcrumbs: false,
        },
        {
          id: "prazo-medio",
          title: "PRAZO MÉDIO",
          type: "item",
          url: "prazo-medio",
          breadcrumbs: false,
        },
        {
          id: "ticket-medio",
          title: "TICKET MÉDIO",
          type: "item",
          url: "ticket-medio",
          breadcrumbs: false,
        },
        {
          id: "dados-consolidados-por-dia",
          title: "DADOS CONSOLIDADOS",
          type: "item",
          url: "dados-consolidados-por-dia",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "cupons",
      title: "CUPONS",
      type: "item",
      url: "cupons",
      icon: DiscountIcon,
      breadcrumbs: false,
      permissao: "visualizar-cupons",
    },
    {
      id: "envios-emails",
      title: "ENVIO EMAILS",
      type: "item",
      url: "envios-emails",
      icon: EmailIcon,
      breadcrumbs: false,
    },
    {
      id: "envios-sms",
      title: "ENVIO SMS",
      type: "item",
      url: "envios-sms",
      icon: FeedbackIcon,
      breadcrumbs: false,
    },
    {
      id: "voltar",
      title: "VOLTAR DASH PRINCIPAL",
      type: "item",
      url: "/area-admin",
      icon: ArrowBackIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboardMarketing;
