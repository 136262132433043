import Loadable from "@/components/Loadable";
import MainLayout from "@/layout/MainLayout";
import { lazy } from "react";

const Configuracoes = Loadable(
  lazy(() => import("@/pages/gestao/configuracao"))
);

const Avisos = Loadable(
  lazy(() => import("@/pages/gestao/configuracao/avisos"))
);
const CadastroAviso = Loadable(
  lazy(() => import("@/pages/gestao/configuracao/avisos/cadastro-aviso"))
);
const DetalhesAviso = Loadable(
  lazy(() => import("@/pages/gestao/configuracao/avisos/detalhes-aviso"))
);

const Taxas = Loadable(lazy(() => import("@/pages/gestao/configuracao/taxas")));
const Gateways = Loadable(lazy(() => import("@/pages/gestao/gateways")));

const Metas = Loadable(lazy(() => import("@/pages/gestao/configuracao/metas")));
const RegistrarMetas = Loadable(
  lazy(() => import("@/pages/gestao/configuracao/metas/registrar-metas"))
);
const EditarMeta = Loadable(
  lazy(() => import("@/pages/gestao/configuracao/metas/editar-meta"))
);
const ConfiguracaoPagamentos = Loadable(
  lazy(() => import("@/pages/gestao/configuracao/pagamentos"))
);
const CadastrarConfiguracaoPagamentos = Loadable(
  lazy(() =>
    import("@/pages/gestao/configuracao/pagamentos/registrar-configuracao")
  )
);
const EditarConfiguracaoPagamento = Loadable(
  lazy(() =>
    import("@/pages/gestao/configuracao/pagamentos/editar-configuracao")
  )
);

const ConfigurarBlacklist = Loadable(
  lazy(() => import("@/pages/gestao/configuracao/blacklist"))
);

const RegistrarBlacklist = Loadable(
  lazy(() =>
    import("@/pages/gestao/configuracao/blacklist/registrar-blacklist")
  )
);
const EditarBlacklist = Loadable(
  lazy(() => import("@/pages/gestao/configuracao/blacklist/editar-blacklist"))
);

const Relatorios = Loadable(lazy(() => import("@/pages/gestao/relatorios")));
const RelatorioDebitosXTransacionado = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/debitos-x-transacionado"))
);
const ClientesNovosPorDia = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/clientes-novos-por-dia"))
);
const OperacoesPorGateway = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/operacoes-por-gateway"))
);
const TotalOperacoesPorGateway = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/total-operacoes-por-gateway"))
);
const OperacoesPorUF = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/operacoes-por-uf"))
);
const TotalOperacoesPorUF = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/total-operacoes-por-uf"))
);

const OperacoesPorParcela = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/operacoes-por-parcela"))
);

const TotalClientes = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/total-clientes"))
);

const PrazoMedio = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/prazo-medio"))
);
const ValorTotalDebitos = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/valor-total-debitos"))
);
const SimulacoesPorDia = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/simulacoes-por-dia"))
);

const OperacoesFinalizadasPorDia = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/operacoes-finalizadas-por-dia"))
);
const TotaisChargeback = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/totais-chargeback"))
);
const TotaisPorTipoPagamento = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/totais-por-tipo-pagamento"))
);

const TaxaDeConversao = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/taxa-conversao"))
);

const CustosPorDia = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/custos-por-dia"))
);

const CustosDeOperacao = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/custo-de-operacoes"))
);

const FaturamentoPorDia = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/faturamento-por-dia"))
);

const PercentualCustosGerais = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/percentual-custos-gerais"))
);

const FaturamentoPorPeriodo = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/faturamento-por-periodo"))
);

const GeralPorDia = Loadable(
  lazy(() => import("@/pages/gestao/relatorios/geral-por-dia"))
);

const Operacoes = Loadable(lazy(() => import("@/pages/operacoes")));
const Pedido = Loadable(lazy(() => import("@/pages/operacoes/pedido")));
const DashboardMetas = Loadable(lazy(() => import("@/pages/metas")));

const Estorno = Loadable(
  lazy(() => import("@/pages/operacoes/pedido/estorno"))
);
const Comprovante = Loadable(
  lazy(() =>
    import(
      "@/pages/operacoes/pedido/pagamentos-solicitados/comprovante-pagamento"
    )
  )
);
const ComprovanteEstorno = Loadable(
  lazy(() =>
    import(
      "@/pages/operacoes/pedido/pagamentos-solicitados/comprovante-estorno"
    )
  )
);
const ExtratoOperacoesLiquidadas = Loadable(
  lazy(() => import("@/pages/operacoes/resumo/extrato-operacoes-liquidadas"))
);

const Clientes = Loadable(lazy(() => import("@/pages/operacoes/clientes")));
const Usuario = Loadable(
  lazy(() => import("@/pages/operacoes/clientes/usuario"))
);
const AlteracaoUsuario = Loadable(
  lazy(() => import("@/pages/operacoes/clientes/alteracao-usuario"))
);
const IndicacoesClientes = Loadable(
  lazy(() => import("@/pages/operacoes/clientes/indicacoes-clientes"))
);

const EsteiraCP = Loadable(
  lazy(() => import("@/pages/operacoes/esteira-pedidos-cp"))
);

const Parceiros = Loadable(lazy(() => import("@/pages/parceiros/parceiro")));
const PagamentosParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/pagamentos"))
);
const UsuariosParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/usuarios-parceiro"))
);
const EdicaoParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/edicao-parceiro"))
);
const EdicaoWhiteLabelParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/white-label"))
);
const EdicaoTCParceiro = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/editar-tc"))
);
const EdicaoFaixaComissao = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/editar-faixa-comissao"))
);
const EditarTaxasParcelas = Loadable(
  lazy(() => import("@/pages/parceiros/parceiro/editar-taxas-por-parcela"))
);

const Transferencias = Loadable(
  lazy(() => import("@/pages/parceiros/transferencias"))
);
const ComprovanteTransferencia = Loadable(
  lazy(() => import("@/pages/parceiros/transferencias/comprovante"))
);

const RelatorioGeralParceiros = Loadable(
  lazy(() => import("@/pages/parceiros/relatorios/geral-parceiros"))
);

const RelatorioDetalhamentoParceiros = Loadable(
  lazy(() => import("@/pages/parceiros/relatorios/detalhamento-parceiros"))
);

const MetadataMarketing = Loadable(
  lazy(() => import("@/pages/marketing/metadata"))
);
const RelatorioCohort = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/cohort"))
);
const RelatorioCuponsUtilizados = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/cupons-utilizados"))
);

const RelatorioTagsConsolidado = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/dados-tags-consolidados"))
);

const RelatorioFrequenciaCompra = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/frequencia-compra"))
);

const RelatorioProspeccaoAtivo = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/leads-prospeccao-ativa"))
);

const RelatorioPrazoMedio = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/prazo-medio"))
);

const RelatorioTicketMedio = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/ticket-medio"))
);

const RelatorioDadosConsolidadosPorDia = Loadable(
  lazy(() => import("@/pages/marketing/relatorios/dados-consolidados-por-dia"))
);
const CampanhasMarketing = Loadable(
  lazy(() => import("@/pages/marketing/campanhas"))
);
const CadastroCampanhasMarketing = Loadable(
  lazy(() => import("@/pages/marketing/campanhas/cadastro"))
);
const EdicaoCampanhasMarketing = Loadable(
  lazy(() => import("@/pages/marketing/campanhas/editar"))
);
const Cupons = Loadable(lazy(() => import("@/pages/marketing/cupons")));
const CadastroCupons = Loadable(
  lazy(() => import("@/pages/marketing/cupons/cadastro-cupons"))
);
const EditarCupom = Loadable(
  lazy(() => import("@/pages/marketing/cupons/editar-cupom"))
);
const EnvioEmails = Loadable(
  lazy(() => import("@/pages/marketing/envios-emails"))
);
const EnvioSms = Loadable(lazy(() => import("@/pages/marketing/envios-sms")));

const TiposDeCusto = Loadable(
  lazy(() => import("@/pages/gestao/tipos-de-custo"))
);
const CadastroTipoDeCusto = Loadable(
  lazy(() => import("@/pages/gestao/tipos-de-custo/cadastro"))
);
const EdicaoTipoDeCusto = Loadable(
  lazy(() => import("@/pages/gestao/tipos-de-custo/edicao"))
);

const Custos = Loadable(lazy(() => import("@/pages/gestao/custos")));
const CadastroCusto = Loadable(
  lazy(() => import("@/pages/gestao/custos/cadastro"))
);
const EdicaoCusto = Loadable(
  lazy(() => import("@/pages/gestao/custos/edicao"))
);

const Lancamentos = Loadable(lazy(() => import("@/pages/gestao/lancamentos")));
const EdicaoLancamento = Loadable(
  lazy(() => import("@/pages/gestao/lancamentos/edicao"))
);

const GestaoRoutes = {
  path: "area-admin/gestao",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <Operacoes />,
    },
    {
      path: "operacoes",
      element: <Operacoes />,
    },
    {
      path: "pedido/:idRequest",
      element: <Pedido />,
    },
    {
      path: "dashboard-metas",
      element: <DashboardMetas />,
    },
    {
      path: "recibo-operacao/:idRequest/:token/:idRequestBill",
      element: <Comprovante />,
    },
    {
      path: "pedido/:idRequest/estorno",
      element: <Estorno />,
    },
    {
      path: "comprovante-estorno/:idRequest/:idRequestBill",
      element: <ComprovanteEstorno />,
    },
    {
      path: "operacoes-liquidadas",
      element: <ExtratoOperacoesLiquidadas />,
    },
    {
      path: "indicacoes-clientes",
      element: <IndicacoesClientes />,
    },
    {
      path: "clientes",
      element: <Clientes />,
    },
    {
      path: "usuario",
      element: <Usuario />,
    },
    {
      path: "usuario/:idUser",
      element: <AlteracaoUsuario />,
    },
    {
      path: "esteira-cp",
      element: <EsteiraCP />,
    },
    {
      path: "clientes",
      element: <Clientes />,
    },
    {
      path: "listagem-parceiros",
      element: <Parceiros />,
    },
    {
      path: "pagamentos-parceiro/:idCompany",
      element: <PagamentosParceiro />,
    },
    {
      path: "usuarios-parceiro/:idCompany",
      element: <UsuariosParceiro />,
    },
    {
      path: "editar-parceiro/:idCompany",
      element: <EdicaoParceiro />,
    },
    {
      path: "white-label/:idCompany",
      element: <EdicaoWhiteLabelParceiro />,
    },
    {
      path: "tc-parceiro/:idCompany",
      element: <EdicaoTCParceiro />,
    },
    {
      path: "faixa-comissao/:idCompany",
      element: <EdicaoFaixaComissao />,
    },
    {
      path: "taxas-parcelas/:idCompany",
      element: <EditarTaxasParcelas />,
    },
    {
      path: "usuario/company/:idCompany",
      element: <Usuario />,
    },
    {
      path: "usuario/company/:idCompany/:idUser",
      element: <Usuario />,
    },
    {
      path: "transferencias",
      element: <Transferencias />,
    },
    {
      path: "tipos-de-custos",
      element: <TiposDeCusto />,
    },
    {
      path: "cadastro-tipo-de-custo",
      element: <CadastroTipoDeCusto />,
    },
    {
      path: "edicao-tipo-custo/:idTipoCusto",
      element: <EdicaoTipoDeCusto />,
    },
    {
      path: "custos",
      element: <Custos />,
    },
    {
      path: "cadastrar-custo",
      element: <CadastroCusto />,
    },
    {
      path: "edicao-custo/:idCusto",
      element: <EdicaoCusto />,
    },
    {
      path: "lancamentos",
      element: <Lancamentos />,
    },
    {
      path: "edicao-lancamento/:idLancamentoCusto",
      element: <EdicaoLancamento />,
    },
    {
      path: "comprovante-transferencia/:idTransfer",
      element: <ComprovanteTransferencia />,
    },
    {
      path: "geral-parceiros",
      element: <RelatorioGeralParceiros />,
    },
    {
      path: "detalhamento-parceiros",
      element: <RelatorioDetalhamentoParceiros />,
    },
    {
      path: "metadata",
      element: <MetadataMarketing />,
    },
    {
      path: "campanhas",
      element: <CampanhasMarketing />,
    },
    {
      path: "registro-campanhas",
      element: <CadastroCampanhasMarketing />,
    },
    {
      path: "edicao-campanha/:idCampaign",
      element: <EdicaoCampanhasMarketing />,
    },
    {
      path: "cohort",
      element: <RelatorioCohort />,
    },
    {
      path: "cupons-utilizados",
      element: <RelatorioCuponsUtilizados />,
    },
    {
      path: "tags-consolidados",
      element: <RelatorioTagsConsolidado />,
    },
    {
      path: "frequencia-compra",
      element: <RelatorioFrequenciaCompra />,
    },
    {
      path: "prospeccao-ativa",
      element: <RelatorioProspeccaoAtivo />,
    },
    {
      path: "prazo-medio-clientes-novos",
      element: <RelatorioPrazoMedio />,
    },
    {
      path: "ticket-medio",
      element: <RelatorioTicketMedio />,
    },
    {
      path: "dados-consolidados-por-dia",
      element: <RelatorioDadosConsolidadosPorDia />,
    },
    {
      path: "cupons",
      element: <Cupons />,
    },
    {
      path: "cadastro-cupons",
      element: <CadastroCupons />,
    },
    {
      path: "editar-cupom/:idCupom",
      element: <EditarCupom />,
    },
    {
      path: "envios-emails",
      element: <EnvioEmails />,
    },
    {
      path: "envios-sms",
      element: <EnvioSms />,
    },
    {
      path: "configuracoes",
      element: <Configuracoes />,
    },
    {
      path: "relatorios",
      element: <Relatorios />,
    },
    {
      path: "debitos-x-transacionado",
      element: <RelatorioDebitosXTransacionado />,
    },
    {
      path: "clientes-novos-por-dia",
      element: <ClientesNovosPorDia />,
    },
    {
      path: "operacoes-por-gateway",
      element: <OperacoesPorGateway />,
    },
    {
      path: "total-op-por-gateway",
      element: <TotalOperacoesPorGateway />,
    },
    {
      path: "operacoes-por-parcela",
      element: <OperacoesPorParcela />,
    },
    {
      path: "total-clientes",
      element: <TotalClientes />,
    },
    {
      path: "operacoes-finalizadas-por-dia",
      element: <OperacoesFinalizadasPorDia />,
    },
    {
      path: "prazo-medio",
      element: <PrazoMedio />,
    },
    {
      path: "valor-total-de-debitos",
      element: <ValorTotalDebitos />,
    },
    {
      path: "valor-total-de-debitos",
      element: <ValorTotalDebitos />,
    },
    {
      path: "simulacoes-por-dia",
      element: <SimulacoesPorDia />,
    },
    {
      path: "operacoes-por-uf",
      element: <OperacoesPorUF />,
    },
    {
      path: "total-op-por-uf",
      element: <TotalOperacoesPorUF />,
    },
    {
      path: "totais-chargeback",
      element: <TotaisChargeback />,
    },
    {
      path: "totais-por-tipo-pagamento",
      element: <TotaisPorTipoPagamento />,
    },
    {
      path: "taxa-conversao",
      element: <TaxaDeConversao />,
    },
    {
      path: "custos-por-dia",
      element: <CustosPorDia />,
    },
    {
      path: "custos-de-operacoes",
      element: <CustosDeOperacao />,
    },
    {
      path: "faturamento-por-dia",
      element: <FaturamentoPorDia />,
    },
    {
      path: "percentual-custos-gerais",
      element: <PercentualCustosGerais />,
    },
    {
      path: "faturamento-por-periodo",
      element: <FaturamentoPorPeriodo />,
    },
    {
      path: "geral-por-dia",
      element: <GeralPorDia />,
    },
    {
      path: "avisos",
      element: <Avisos />,
    },
    {
      path: "cadastro-aviso",
      element: <CadastroAviso />,
    },
    {
      path: "detalhes-aviso/:id",
      element: <DetalhesAviso />,
    },
    {
      path: "taxas",
      element: <Taxas />,
    },
    {
      path: "gateways",
      element: <Gateways />,
    },
    {
      path: "metas",
      element: <Metas />,
    },
    {
      path: "registrar-metas-do-mes",
      element: <RegistrarMetas />,
    },
    {
      path: "editar-meta",
      element: <EditarMeta />,
    },
    {
      path: "configuracao-pagamentos",
      element: <ConfiguracaoPagamentos />,
    },
    {
      path: "registrar-configuracao-pagamentos",
      element: <CadastrarConfiguracaoPagamentos />,
    },
    {
      path: "editar-configuracao-pagamento/:idConfig",
      element: <EditarConfiguracaoPagamento />,
    },
    {
      path: "configurar-blacklist",
      element: <ConfigurarBlacklist />,
    },
    {
      path: "registrar-blacklist",
      element: <RegistrarBlacklist />,
    },
    {
      path: "editar-blacklist/:idRequestsBlackList",
      element: <EditarBlacklist />,
    },
    {
      path: "*",
      element: <Operacoes />,
    },
  ],
};

export default GestaoRoutes;
