import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MoneyIcon from "@mui/icons-material/Money";

const dashboardParceiros = {
  id: "group-dashboard-parceiros",
  title: "Parceiros",
  url: "/area-admin/parceiros",
  type: "group",
  children: [
    {
      id: "",
      title: "RESUMO",
      type: "item",
      url: "",
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "listagem-parceiros",
      title: "PARCEIROS",
      type: "item",
      url: "listagem-parceiros",
      icon: BusinessIcon,
      breadcrumbs: false,
    },
    {
      id: "transferencias",
      title: "SALDOS/TRANSFERÊNCIAS",
      type: "item",
      url: "transferencias",
      icon: MoneyIcon,
      breadcrumbs: false,
      permissao: "visualizar-saldos",
      badge: true,
    },
    {
      title: "RELATÓRIOS",
      type: "collapse",
      defaultExpanded: false,
      children: [
        {
          id: "geral-parceiros",
          title: "GERAL PARCEIROS",
          type: "item",
          url: "geral-parceiros",
          breadcrumbs: false,
        },
        {
          id: "detalhamento-parceiros",
          title: "DETALHAMENTO PARCEIROS",
          type: "item",
          url: "detalhamento-parceiros",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "voltar",
      title: "VOLTAR DASH PRINCIPAL",
      type: "item",
      url: "/area-admin",
      icon: ArrowBackIcon,
      breadcrumbs: false,
    },
  ],
};

export default dashboardParceiros;
