import dashboard from "./dashboard-geral";
import dashboardGestao from "./dashboard-gestao";
import dashboardMarketing from "./dashboard-marketing";
import dashboardMetas from "./dashboard-metas";
import dashboardMovipay from "./dashboard-movipay";
import dashboardOperacoes from "./dashboard-operacoes";
import dashboardParceiros from "./dashboard-parceiros";
import dashboardSistema from "./dashboard-sistema";

const menuItems = {
  items: [dashboard],
  marketing: [dashboardMarketing],
  parceiros: [dashboardParceiros],
  operacoes: [dashboardOperacoes],
  sistema: [dashboardSistema],
  gestao: [dashboardGestao],
  movipay: [dashboardMovipay],
  metas: [dashboardMetas],
};

export default menuItems;
